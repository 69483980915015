<template>
  <section class="index-finance">
    <div class="index-finance__container container">
      <div class="index-finance__card">
        <div class="info">
          <h2 class="title" v-t="'index.finance.title'" />
          <ul class="list">
            <li v-for="(item, i) in list" :key="i">
              <span v-t="`index.finance.list.${item}`" />
            </li>
          </ul>
          <a target="_blank" href="https://www.roobee.finance/" class="start">
            <span>{{ $t('index.finance.start') }}</span>
          </a>
        </div>
        <div class="website">
          <div class="top">
            <div class="buttons">
              <div class="item"></div>
              <div class="item"></div>
              <div class="item"></div>
            </div>
            <div class="search-area">
              <span class="url">roobee.finance</span>
            </div>
          </div>
          <div class="content">
            <img src="/images/finance/website.png" alt="Roobee Finance">
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Finance",

  props: ['list']
}
</script>

<style lang="scss">
@import '@/assets/scss/elements/index/finance';
</style>